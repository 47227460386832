<template>
  <div class="department">
    <el-cascader
      style="width: 100%"
      size="medium"
      v-model="_value"
      :options="data"
      :props="defaultProps"
      :disabled="disabled"
    ></el-cascader>
  </div>
</template>

<script>
import { removeLeaf } from "@/utils/utils";

export default {
  name: "Department",
  data() {
    return {
      data: [],
      defaultProps: {
        label: "deptName",
        value: "id",
        children: "child",
        emitPath: false,
        multiple: this.$attrs.hasOwnProperty("multiple"),
        checkStrictly:!!(this.$attrs.checkStrictly)
      },
    };
  },
  props: {
    value: {
      type: Number | Array,
    },
    disabled: {
      typeof: Boolean,
      default: false
    },
    // 过滤商户和安保
    filterShop: {
      typeof: Boolean,
      default: false
    }
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    init() {
      this.$api.personnel.sysDeptTree().then((res) => {
        if (this.filterShop) {
          let data = removeLeaf([res.data], "child");
          data[0].child = data[0].child.filter(item => item.deptName !== '商户' && item.deptName !== '安保')
          this.data = data
        } else {
          this.data = removeLeaf([res.data], "child");
        }
      });
      return;
    },
  },
  created() {
    this.init();
  },
};
</script>

<style lang="scss">
.department {
}
</style>