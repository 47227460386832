<template>
  <div class="positive edit-wrapper">
    <div class="form-content-wrapper">
      <div class="form-content">
        <el-form ref="positiveForm" :model="examData" :rules="formRules" label-width="150px" size="medium">
          <el-row>
            <el-col :span="12">
              <el-form-item label="员工编号" prop="jobNumber">
                <el-select  v-model="examData.jobNumber" remote filterable
                            @change="handleSelect" :remote-method="getStaffs"
                            :loading="staffLoading" :disabled="examData.status==3"
                            placeholder="输入姓名/员工编号进行搜索">
                  <el-option v-for="item in staffs"
                             :key="item.jobNumber"
                             :label="item.jobNumber"
                             :value="item.jobNumber">
                    <span title="工号">{{item.jobNumber}}</span>
                    <span title="姓名">{{'('+item.staffName+')'}}</span>
                    <br>
                    <span class="auto-complete-idcard" title="身份证号">{{item.idCard}}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="姓名" prop="staffName">
                <el-input v-model="examData.staffName" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="聘用岗位" prop="positionIds">
                <position :checkStrictly="true" v-model="examData.positionIds"></position>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="聘用部门" prop="deptId">
                <department :checkStrictly="true" v-model="examData.deptId"></department>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="本次合同主体" prop="contractsPartId">
                <el-select v-model="examData.contractsPartId" @change="changeContracts">
                  <el-option v-for="itr in Contracts" :key="itr.value" :label="itr.text" :value="itr.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="试用期开始日期" prop="beginTrailTime">
                <date v-model="examData.beginTrailTime"></date>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="试用期结束日期" prop="endTrailTime">
                <date v-model="examData.endTrailTime"></date>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="迟到次数" prop="lateCount">
                <el-input v-model="examData.lateCount" maxlength="8" placeholder="请输入有效的整数或小数" oninput="value=value.replace(/[^\d\.]/g,'')"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="早退次数" prop="earlyCount">
                <el-input v-model="examData.earlyCount" maxlength="8" placeholder="请输入有效的整数或小数" oninput="value=value.replace(/[^\d\.]/g,'')"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="矿工天数" prop="absenteeismDay">
                <el-input v-model="examData.absenteeismDay"  placeholder="请输入有效的整数或小数" maxlength="8" oninput="value=value.replace(/[^\d\.]/g,'')"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="事假天数" prop="personalLeaveDay">
                <el-input v-model="examData.personalLeaveDay"  placeholder="请输入有效的整数或小数" maxlength="8" oninput="value=value.replace(/[^\d\.]/g,'')"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="病假天数" prop="sickLeaveDay">
                <el-input v-model="examData.sickLeaveDay"  placeholder="请输入有效的整数或小数" maxlength="8" oninput="value=value.replace(/[^\d\.]/g,'')"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="试用目标考核得分" prop="targetAssessmentScore">
                <el-input v-model="examData.targetAssessmentScore"  placeholder="请输入有效的整数或小数" maxlength="8" oninput="value=value.replace(/[^\d\.]/g,'')"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="综合素质评估得分" prop="comprehensiveQualityScore">
                <el-input v-model="examData.comprehensiveQualityScore"  placeholder="请输入有效的整数或小数" maxlength="8" oninput="value=value.replace(/[^\d\.]/g,'')"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="转正述职答辩得分" prop="lectureReportScore">
                <el-input v-model="examData.lectureReportScore"  placeholder="请输入有效的整数或小数" maxlength="8" oninput="value=value.replace(/[^\d\.]/g,'')"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="审批类型" prop="activityKeyValue">
                <el-select v-model="examData.activityKeyValue" @change="getFlowModelByApType" :disabled="examData.status==3">
                  <el-option v-for="itr in ApprovalTypes" :key="itr.activityKeyValue" :label="itr.name" :value="itr.activityKeyValue"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-divider class="line-dividing"><span>流程图</span></el-divider>
        <div class="diagram-content">
          <flow :flows.sync="bpmActivityModel" :status="examData.status"></flow>
        </div>
      </div>
    </div>
    <div class="occupy-ele"></div>
    <div class="button-group fixed-bottom-btn">
      <el-button class="btn-default" size="medium" @click="back">返回</el-button>
      <el-button class="btn-reset mgl20" size="medium" @click="resetForm" v-if="examData.status==6">重置</el-button>
      <el-button class="btn-submit mgl20" size="medium" type="primary" @click="submit">提交</el-button>
    </div>
  </div>
</template>

<script>
import Department from "../../../../components/department";
import Position from "../../../../components/position.vue";
import Date from "../../../../components/date.vue";
import {floatNum} from "../../../../utils/validator";
import Flow from "../../../../components/flow";
import DetailFlow from "../../../../components/detail-flow";
import {validateApprover} from "../../../../utils/utils";
export default {
  name: "positive-edit",
  components:{DetailFlow, Flow, Position, Department,Date},
  data(){
    return {
      timer:null,
      isEdited:false,
      staffLoading:false,
      examData:{
        activityKeyValue: '',
        id:'',
        beginTrailTime : '',
        endTrailTime : '',
        earlyCount: 0,
        lateCount: 0,
        contractsPartId:'',
        lectureReportScore: 0,
        personalLeaveDay: 0,
        sickLeaveDay: 0,
        absenteeismDay: 0,
        targetAssessmentScore: 0,
        comprehensiveQualityScore: 0,
      }, // 表单对象
      bpmActivityModel:[], // 流程节点
      Contracts:[], // 劳动合同主体数据list
      pickerOptions:{
        disabledDate(time){
          return time.getTime() > Date.now()
        }
      },
      ApprovalTypes:[], // 审批类型
      formRules:{},
      typeId:this.$store.state.PERSONNEL.POSITIVE_TYPEID,
      staffs:[]
    }
  },
  props:['id','taskId'],
  watch:{
    examData:{
      handler:function(newVal,oldVal){
        if(newVal.id&&oldVal.id!==''){
          this.isEdited=true;
          if(newVal.status!=3){
            if(this.timer){
              clearTimeout(this.timer)
            }
            this.timer = setTimeout( this.updateDraft, 500)
          }
        }
      },
      deep:true
    }
  },
  created() {
    // 初始化select list
    this.getContracts();
    this.getStaffs();
    this.init();
  },
  destroyed() {
    clearTimeout(this.timer)
  },
  methods:{
    async init(){
      await this.getApprovalTypes();
      if (this.$route.params.id||this.id) {  // 驳回待修改编辑
        this.getDetail(this.$route.params.id||this.id)
      }else{
        this.queryDraft()
      }
    },
    initRules(){
      this.formRules = {
        jobNumber:[
          this.$formValidator.empty("工号",'change')
        ],
        staffName:[
          {required:true,message:'姓名',trigger:'change'}
        ],
        contractsPartId:[
          this.$formValidator.empty("本次合同主体",'change', "number")
        ],
        deptId:[
          this.$formValidator.empty("聘用部门",'change','number')
        ],
        positionIds:[
          {required:true,message:'请选择聘用岗位',trigger:'change'}
        ],
        beginTrailTime:[
          this.$formValidator.empty("试用期开始日期",'change')
        ],
        endTrailTime:[
          this.$formValidator.empty("试用期结束日期",'change')
        ],
        earlyCount:[
          {required:true,message:'请输入早退次数',trigger:'change'},
          {validator:floatNum(1)}
        ],
        lateCount:[
          {required:true,message:'请输入迟到次数',trigger:'change'},
          {validator:floatNum(1)}
        ],
        absenteeismDay:[
          {required:true,message:'请输入矿工天数',trigger:'change'},
          {validator:floatNum(1)}
        ],
        personalLeaveDay:[
          {required:true,message:'请输入事假天数',trigger:'change'},
          {validator:floatNum(1)}
        ],
        sickLeaveDay:[
          {required:true,message:'请输入病假天数',trigger:'change'},
          {validator:floatNum(1)}
        ],
        targetAssessmentScore:[
          {required:true,message:'请输入试用目标考核得分',trigger:'change'},
          {validator:floatNum(2)}
        ],
        comprehensiveQualityScore:[
          {required:true,message:'请输入综合素质评估得分',trigger:'change'},
          {validator:floatNum(2)}
        ],
        lectureReportScore:[
          {required:true,message:'请输入转正述职答辩得分',trigger:'change'},
          {validator:floatNum(2)}
        ],
        activityKeyValue:[
          this.$formValidator.empty("审批类型",'change','number')
        ]
      }
    },
    getContracts(){
      this.$api.personnel.getContractsList({pageNum:1,pageSize:10000}).then(response=>{
        this.Contracts = response.data;
      })
    },
    changeContracts(e){
      this.examData.contractsPartA = this.Contracts.filter(itr=>itr.value==e)[0].text
    },
    getStaffs(query){
      let param = {
        staffNameOrJobNumber:query||'',
        pageSize:10000,
        pageNum:1
      }
      this.staffLoading = true;
      this.$api.personnel.staffRosterList(param).then(res=>{
        this.staffLoading = false;
        this.staffs  = res.data.list;
      })
    },
    getApprovalTypes(){
      this.$api.personnel.getApprovalType({type:this.typeId}).then(response=>{
        this.ApprovalTypes = response.data.list
      })
    },

    // 重置
    resetForm(){
      let data = {
        activityKeyValue: this.ApprovalTypes[0].activityKeyValue,
        id:this.examData.id,
        beginTrailTime : '',
        endTrailTime : '',
        contractsPartId:'',
        status:6,
        earlyCount: 0,
        lateCount: 0,
        lectureReportScore: 0,
        personalLeaveDay: 0,
        sickLeaveDay: 0,
        absenteeismDay: 0,
        targetAssessmentScore: 0,
        comprehensiveQualityScore: 0,
      }
      this.$api.personnel.reset({typeId:this.typeId}).then(res=>{
        this.examData=data;
        this.getFlowModelByApType()
      })
    },
    handleSelect(e){
      let select = this.staffs.filter((item=>{
        return item && item.jobNumber==e
      }))[0]
      this.examData.staffName = select.staffName;
      this.examData.deptId = parseInt(select.deptId);
      this.examData.positionIds = select.positionIds?select.positionIds:[];
      this.examData.staffRosterId = select.id;
      this.examData.contractsPartId = select.contractsPartId? select.contractsPartId:'';
      this.examData.beginTrailTime = select.beginTrailTime? select.beginTrailTime:'';
      this.examData.endTrailTime = select.endTrailTime?select.endTrailTime:'';
      this.examData.earlyCount = select.earlyCount||0;
      this.examData.absenteeismDay = select.absenteeismDay||0;
      this.examData.lateCount = select.lateCount||0;
      this.examData.sickLeaveDay = select.sickLeaveDay||0;
      this.examData.personalLeaveDay = select.personalLeaveDay||0;
      if(this.examData.contractsPartId){
        this.examData.contractsPartA = this.Contracts.filter(itr=>itr.value==this.examData.contractsPartId)[0].text
      }
    },

    // 返回
    back(){
      if(this.id){
        this.$emit('changeType',  true)
      }else{
        this.$router.replace('/businessModule/personnel/positive')
      }
    },

    // 查询草稿
    queryDraft(){
      this.$api.personnel.queryPositiveDraft({}).then(res=>{
        if(res.data&&res.data.id){ // 如果有草稿 就编辑草稿
          this.examData = res.data;
          if(!this.examData.activityKeyValue){
            this.examData.activityKeyValue = this.ApprovalTypes[0].activityKeyValue;
          }
          this.getFlowModelByApType();
        }else{ // 如果没有草稿 就新增草稿
          this.addDraft()
        }
      })
    },

    // 新增草稿
    addDraft(){
      this.examData.status=6;
      let param = JSON.parse(JSON.stringify(this.examData))
      this.$api.personnel.insertPositiveDraft(param).then(res=>{
        this.examData.id = res.data.id
        // 初始审批类型
        this.examData.activityKeyValue = this.ApprovalTypes[0].activityKeyValue
        this.getFlowModelByApType();
      })
    },

    // 更新草稿
    updateDraft(){
      // 如果用户已经点击了提交按钮，这个时候应该return;
      if(this.examData.status==3){
        return ;
      }
      let param =JSON.parse(JSON.stringify(this.examData))
      this.$api.personnel.updatePositiveDraft(param).then(res=>{

      })
    },

    submit(){
      // 驳回待修改状态的数据 必须修改表单之后才能提交
      if(!this.isEdited&&this.examData.status==3){
        return this.$message.error('表单内容未修改，请修改后提交')
      }
      if(!Object.keys(this.formRules).length){
        this.initRules();
      }
      this.$nextTick(()=>{
        this.$refs.positiveForm.validate(valid=>{
          if(valid){
            if(this.examData.beginTrailTime&&this.examData.endTrailTime&&this.examData.beginTrailTime>this.examData.endTrailTime){
              return this.$message.error('试用期开始日期不能大于试用期结束日期')
            }
            let node = JSON.parse(JSON.stringify(this.bpmActivityModel))
            if (!validateApprover(node)) {
              return;
            }
            let param = Object.assign({},this.examData)
            param.activityNodeDetails = node;
            param.typeId = this.typeId;
            let contract = this.Contracts.filter(itr=>itr.value==this.examData.contractsPartId);
            param.contractsPartA = contract.length?contract[0].text:'';
            let handler = this.examData.status==3?this.$api.personnel.reSubmitPositive({
              "beginTrailTime": this.examData.beginTrailTime,
              "contractsPartA": param.contractsPartA,
              "contractsPartId": this.examData.contractsPartId,
              "deptId": this.examData.deptId,
              "endTrailTime": this.examData.endTrailTime,
              "earlyCount": this.examData.earlyCount,
              "lateCount": this.examData.lateCount,
              "lectureReportScore": this.examData.lectureReportScore,
              "personalLeaveDay": this.examData.personalLeaveDay,
              "sickLeaveDay": this.examData.sickLeaveDay,
              "absenteeismDay": this.examData.absenteeismDay,
              "targetAssessmentScore": this.examData.targetAssessmentScore,
              "comprehensiveQualityScore": this.examData.comprehensiveQualityScore,
              "id": this.examData.id,
              "positionIds": this.examData.positionIds
            }): this.$api.personnel.submitPositive(param);
            handler.then(response=>{
              this.$message.success('提交成功')
              this.back()
            })
          }
        })
      })
    },

    // 根据审批类型获取流程模板
    getFlowModelByApType() {
      this.$api.personnel.bpmGetModelValue(this.examData.activityKeyValue).then((res) => {
        this.bpmActivityModel = []
        let data = res.data.nodeList.map((ele) => {
          ele.userList=ele.userList?ele.userList:[]
          return ele;
        });
        this.bpmActivityModel = data
      });
    },

    //  根据ID获取详情
    getDetail(id){
      this.$api.personnel.getPositiveDataDetail({id:id}).then((res) => {
        this.examData = res.data;
        this.bpmActivityModel  =  JSON.parse(JSON.stringify(res.data.activityModel.activitiNodeDetails));
        if (this.$route.params.id||this.id) {  // 驳回待修改编辑
          this.initRules();
        }
      })
    }
  }

}
</script>

<style lang="scss" scoped>
@import "../common-style/edit-page";
</style>