<template>
  <el-dialog :title="title" :visible.sync="_visible" class="approval-wrapper">
    <el-input placeholder="输入关键字进行过滤" v-model="filterText" class="filter_input" size="medium"></el-input>
    <div class="tree-content">
      <el-tree
        :data="data"
        show-checkbox
        node-key="id"
        ref="tree"
        highlight-current
        @check-change="handleClick"
        :check-strictly="true"
        :props="defaultProps"
        :filter-node-method="filter"
        :default-expanded-keys="[1]"
      >
      </el-tree>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="_visible = false">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "SelectApproval",
  data() {
    return {
      data: null,
      defaultProps: {
        children: "children",
        label: "name",
      },
      selectOrg: {
        orgsid: [],
      },
      selectedData: "",
      isMultiple: this.$attrs.hasOwnProperty("multiple"),
      filterText:''
    };
  },
  props: {
    visible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    title:{
      type:String,
      default() {
        return '选择审批人'
      }
    },
    originSelect:{
      type: Array,
      default() {
        return [];
      },
    }
  },
  watch:{
    filterText(val){
      this.$refs.tree.filter(val)
    }
  },
  computed: {
    _visible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
        this.$emit("changeVisible");
      },
    },
  },
  methods: {
    handleClick(data, checked) {
      if (!this.isMultiple) {
        if (checked) {
          // 单选
          this.$refs.tree.setCheckedNodes([data]);
          this.selectedData = data;
        }
      } else {
        this.selectedData = this.$refs.tree.getCheckedNodes();
      }
    },
    confirm() {
      let result = this.countUserNum(this.selectedData,this.originSelect);
      if(result&&result.length>50){
        return this.$message.warning('选择人数/部门不能超过50')
      }
      this._visible = false;
      this.$emit("confirm", result);
    },
    loadData() {
      this.$api.personnel.sysPeopleTree().then((res) => {
        this.data = [res.data];
        this.data[0].disabled = true;
      });
    },
    countUserNum(value,origin){
      let newArr = JSON.parse(JSON.stringify(origin))
      if(value){
        if (value instanceof Array) {
          // 多选
          value.forEach((e, i) => {
            let res = newArr.some((element) => {
              return (element.id && element.id == e.userId) || (!element.id && !e.userId && element.deptId == e.deptId);
            });
            if (!res&&e.deptId) {
              // 如果userList中不存在，则把该值添加进来
              newArr.push({
                id: e.userId,
                deptId: e.deptId,
                name: e.name,
                deptName: e.deptName,
              });
            }
          });
        } else {
          newArr.push({
            id: value.userId,
            deptId: value.deptId,
            name: value.name,
            deptName: value.deptName,
          });
        }
      }
      return newArr;
    },
    filter(val,data,node){
      if(!val) return true;
      return data.name.includes(val)
    }
  },
  created() {
    this.loadData();
  },
};
</script>

<style lang="scss">
.approval-wrapper{
  .el-dialog__body{
    height:380px;
  }

  .filter_input{
    margin-bottom: 10px;
    width: 400px;
  }
  .tree-content{
    height: 334px;
    overflow-y: auto;
  }
}
</style>