<template>
  <div>
    <el-timeline class="flow-group">
      <el-timeline-item v-for="(item,index) in flowData" :key="index" class="flow-item">
        <template v-if="index === 0&&status!=3"><div class="flow-item_nodeName">{{item.nodeName}}</div></template>
        <template v-else>
          <div class="flow-item_nodeName">{{ item.nodeName }}</div>
          <div
              v-for="(e, i) in item.userList"
              :key="i"
              class="select-person"
          >
            <div class="selected-username" :class="{'edit-username':item.isOwnSetting === 0}">
              <span :title="e.deptName">{{ e.name }}</span>
              <span class="del-user" title="删除" v-if="item.isOwnSetting === 0&&status!=3" @click="removePerson(index, i)"></span>
            </div>
          </div>
          <div>
            <el-button
                @click="selectPerson(item,index)"
                class="add-button"
                size="mini"
                v-if="item.isOwnSetting === 0&&status!=3"
            >+</el-button>
          </div>

        </template>
      </el-timeline-item>
    </el-timeline>
    <select-approval v-if="showSelectPersonTree"
        :visible.sync="showSelectPersonTree"
        @changeVisible="changeApproval(false)"
        :origin-select="selectNode.userList||[]"
        @confirm="confirmApproval"
        multiple
    ></select-approval>
  </div>
</template>

<script>
function dataFilter(arr) {
  // 非叶子节点全部禁用
  return arr.map((e) => {
    if (e.children) {
      if (e.userId) {
       e.children = null
      }else{
        e.children = dataFilter(e.children);
      }
    }
    return e;
  });
}
import SelectApproval from "@/components/selectApproval.vue";
export default {
  name: "flow",
  components: {SelectApproval },
  data(){
    return {
      selectNode:null,
      showSelectPersonTree:false,
    }
  },
  props:['flows','status'],
  computed:{
    "flowData":function (){
      return  this.initNode(this.flows);
    }
  },
  methods:{
    selectPerson(item,index) {
      this.selectNode = item;
      this.showSelectPersonTree = true;
      this.$forceUpdate();
    },
    confirmApproval(value) {
      this.selectNode.userList = value;
      let result = value.reduce((_res,e) => {
        e.id&&_res.users.push(e.id);
        !e.id && e.deptId && _res.departments.push(e.deptId)
        return _res;
      },{users:[],departments:[]});
      this.selectNode.users = result.users.join(',')
      this.selectNode.departments =  result.departments.join(',')
      this.$emit("update:flows",this.flowData)
      this.$emit('updateFlows', this.flowData);
    },
    initNode(data){
      let object = {};
      let result = [];
      if(data&&data.length){
        for(let i=0;i<data.length;i++){
          const key = '+' + data[i].nodeName; // 兼容数字key，确保所有属性都按照插入的顺序遍历
          if(object[key]){
            object[key].userList = object[key].userList.concat(data[i].userList)
          }else{
            object[key]=JSON.parse(JSON.stringify(data[i]));
            object[key].userList = [];
            if(data[i].userList){
              object[key].userList  = object[key].userList.concat(data[i].userList)
            }
          }
        }
        for(let key in object){
          result.push(object[key])
        }
      }
      return result
    },
    changeApproval() {
     this.showSelectPersonTree = false
    },
    removePerson(index,listIndex){
      this.flowData[index].userList.splice(listIndex, 1);
      this.$emit("update:flows",this.flowData)
      this.$emit("updateFlows",this.flowData)
    }
  }
}
</script>

<style scoped lang="scss">
.flow-group{
  .flow-item{
    position: relative;
    .flow-item_nodeName{
     margin-bottom: 10px;
    }
    .select-person{
      display: inline-block;
      cursor: pointer;
      margin:10px 25px 10px 0;
      .edit-username{
        display: inline-block;
        line-height: 1.5;
        background: #f3f3f3;
        border-radius: 4px;
        padding: 2px 10px;
        position: relative;
        .del-user{
          position: absolute;
          top: -5px;
          right: -10px;
          height: 15px;
          width: 15px;
          border-radius: 50%;
          background: #565656;

          &:after{
            content:'×';
            font-size: 14px;
            color: #fff;
            position: absolute;
            top: -3px;
            right: 3px;
          }
        }
      }
    }
  }
}
.add-button {
  margin-top: 8px;
}
</style>